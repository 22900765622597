import React, { useState, useRef } from "react";
import Nav from '../shared/Navigation';
import { Link, useParams, useMatch } from 'react-router-dom';
import Gallery from '../shared/Gallery';
import Video from '../shared/Video';

const Ania = () => {
  const params = useParams();

  return (
    <>
      <div>
        <div className="sub-header ania flex flex-col justify-between">
          <Nav url="/pokoje-u-ani" fbUrl="https://www.facebook.com/PokojeDoWynajecia"/>
          <h1 className="justify-self-end text-white text-5xl md:text-7xl font-bold -mb-[0.5rem] md:-mb-[0.7rem]">Pokoje
            u Ani</h1>
        </div>
        <div className="container mx-auto px-6 mt-10">
          <div className="w-full">
            <div
              className="container grid sm:grid-cols-1 lg:grid-cols-2 pt-6 gap-8">
              <div>
                <Video id="UulSgJt12IY"/>
              </div>
              <div className="lg:pl-8">
                <h2 className="text-3xl font-semibold pb-1 border-b ">Pokoje do wynajęcia w Augustowie</h2>
                <p className="my-4">
                  Oferujemy pokoje 2, 3, 4 osobowe z bezpośrednim wejściem z podwórka.
                </p>
                <p className="mb-4">
                  Pokoje wyposażone są w łazienkę z pełnym węzłem sanitarnym, TV, czajnik oraz lodówkę. Do dyspozycji gości
                  jest także ogólna kuchnia, wyposażona w kuchenkę mikrofalową, kuchenkę elektryczną oraz niezbędne naczynia
                  do przygotowywania posiłków. Na terenie naszej posesji jest także parking, ognisko, plac zabaw dla dzieci oraz grill.
                </p>
                <p className="mb-4">
                  Jesteśmy pomocni w organizacji wycieczek do Wilna bądź innych miast na Liwie jak i Łotwie.
                  Nie opodal nas znajduję się smażalnia ryb jak i kąpielisko z wypożyczalnią sprzętu pływającego.
                  Wypożyczamy rowery górskie.
                </p>
                <p className="mb-4">
                  Zadzwoń do nas, aby uzyskać więcej informacji: <a href="tel:+48693111211"><strong>(+48) 693 111
                  211</strong></a>
                </p>

              </div>
            </div>

            <h2 className="text-4xl mt-4 pb-1 border-b font-semibold">Otoczenie</h2>
            <div
              className="container mx-auto grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 pt-6 gap-8">
              <Gallery quantity={14} title="Pokoje u Ani Augustów" path="pokoje_u_ani/outside"/>
            </div>

            <h2 className="mt-4 pb-1 border-b text-4xl font-semibold">Pokoje 2 osobowe</h2>
            <div
              className="container mx-auto grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 pt-6 gap-8">
              <Gallery quantity={8} title="Pokoje 2-osobowy Augustów" path="pokoje_u_ani/pokoje_2_osobowe"/>
            </div>

            <h2 className="mt-4 pb-1 border-b text-4xl font-semibold">Pokoje 2 - 3 osobowe</h2>

            <div
              className="container mx-auto grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 pt-6 gap-8">
              <Gallery quantity={10} title="Pokoje 2 - 3-osobowe Augustów" path="pokoje_u_ani/pokoje_2-3_osobowe"/>
            </div>

            <h2 className="mt-4 pb-1 border-b text-4xl font-semibold">Pokój 4 osobowy</h2>

            <div
              className="container mx-auto grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 pt-6 gap-8">
              <Gallery quantity={4} title="Pokój 4-osobowy Augustów" path="pokoje_u_ani/pokoj_4_osobowy"/>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Ania;

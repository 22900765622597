import React, { useState } from 'react';
import Item from './Item';

const Gallery = (props) => {
  const items = []

  let i = 1;
  const max = props.quantity + 1

  for (i = 1; i < max; i++) {
    items.push(<Item {...props} id={i} key={i} />)
  }

  return (
    <>
      {items}
    </>
  )
}

export default Gallery;

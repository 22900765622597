import React from "react";
import Header from './shared/Header';

const Contact = () => {
  return (
    <>
      <div>
        <div className="sub-header contact flex flex-col justify-between">
          <Header />
          <h1
            className="justify-self-end text-white text-5xl md:text-7xl font-bold -mb-[0.5rem] md:-mb-[0.7rem]">Kontakt</h1>
        </div>
        <div className="container mx-auto px-6 mt-10">
          <div className="w-full">
            <div
              className="container grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 pt-6 gap-8">
              <div>
                <h3 className="text-3xl font-semibold pb-1 border-b">Pokoje u Ani</h3>
                <address className="mt-4">
                  ul. Turystyczna 5F <br/>
                  16-300 Augustów<br/>
                  woj. podlaskie<br/>
                  <br/>
                  <a href="tel:+48693111211"><strong>+48 693 111 211</strong></a>
                </address>
              </div>
              <div>
                <h3 className="text-3xl font-semibold pb-1 border-b">Pokoje u Krzyśka</h3>
                <address className="mt-4">
                  ul. Turystyczna 5 <br/>
                  16-300 Augustów<br/>
                  woj. podlaskie<br/>
                  <br/>
                  <a href="tel:+48693111211"><strong>+48 693 111 211</strong></a>
                </address>
              </div>
              <div>
                <h3 className="text-3xl font-semibold pb-1 border-b">Dom / Domek Serwy</h3>
                <address className="mt-4">
                  Dom i Domek Serwy <br/>
                  Serwy 28 C<br/>
                  16-326 Płaska<br/>
                  <br/>
                  <a href="tel:+48608070466"><strong>+48 608 070 466</strong></a>
                </address>
              </div>
              <div className="lg:pl-8">
                <h2 className="text-3xl font-semibold pb-1 border-b ">Dane do przelewu</h2>
                <address className="mt-4">
                  Anna Babkowska<br/>
                  ul. Turystyczna 5F<br/>
                  16-300 Augustów<br/>
                  <br/>
                  nr konta: <strong>26 2490 0005 0000 4004 5251 4952</strong>
                </address>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;

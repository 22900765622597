import React, { useState, useRef } from "react";
import Nav from '../shared/Navigation';
import { Link, useParams, useMatch } from 'react-router-dom';
import Gallery from '../shared/Gallery';
import Video from '../shared/Video';

const Serwy = () => {
  return (
    <>
      <div>
        <div className="sub-header serwy flex flex-col justify-between">
          <Nav url="/domek-serwy"/>
          <h1 className="justify-self-end text-white text-5xl md:text-7xl font-bold -mb-[0.5rem] md:-mb-[0.7rem]">Dom /
            Domek Serwy</h1>
        </div>
        <div className="container mx-auto px-6 mt-10">
          <div className="w-full">
            <div
              className="container grid sm:grid-cols-1 lg:grid-cols-2 pt-6 gap-8">
              <div>
                <Video id="NkLLAG4N_N4"/>
              </div>
              <div className="lg:pl-8">
                <h2 className="text-3xl font-semibold pb-1 border-b ">Dom / Domek do wynajęcia Serwy</h2>
                <p className="my-4">
                  Domek jak i Dom położone są bardzo spokojnej okolicy w miejscowości Serwy nad jeziorem Serwy 18 km od
                  Augustowa.
                  Do dyspozycji gości działka o powierzchni 2000m2 która jest ogrodzona i posiada własną linią brzegową
                  wraz z pomostem.
                  Na posesji do dyspozycji naszych gości jest łódź wiosłowa, miejsce na ognisko, grill, hamak, huśtawka
                  czy też leżaki.
                  Na jeziorze strefa ciszy zakaz używania silników spalinowych.
                  Jesteśmy bardzo pomocni naszym gościom, możemy np. zorganizować wycieczkę do Wilna bądź innego miasta
                  na Litwie lub Łotwie
                  Na terenie posesji jest zakaz organizowania imprez.
                </p>
                <h3 className="text-2xl font-semibold pb-1 border-b">Domek</h3>

                <p className="my-4">
                  Domek kompletnie wyposażony. Posiada 3 sypialnie dwu osobowe, kuchnię, łazienkę i salon z TV.
                  Powierzchnia domku 45m2 i tarasu 35m2
                </p>

                <h3 className="text-2xl font-semibold pb-1 border-b">Dom</h3>
                <p className="my-4">
                  Dom murowany z tarasem i z balkonem z którego jest widok na jezioro.
                  Powierzchnia domu to 70m2 w tym na dole salon z TV, kuchnia, łazienka kąpielowa a na górze dwie
                  sypialnie w tym jedna z TV oraz łazienka z toaletą.

                </p>

                <p className="mb-4">
                  Zadzwoń do nas, aby uzyskać więcej informacji: <a href="tel:+48608070466"><strong>(+48) 608 070 466</strong></a>
                </p>
              </div>
            </div>

            <h2 className="text-4xl mt-4 pb-1 border-b font-semibold">Otoczenie</h2>
            <div
              className="container mx-auto grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 pt-6 gap-8">
              <Gallery quantity={12} title="Pokoje u Ani Augustów" path="serwy/outside"/>
            </div>

            <h2 className="mt-4 pb-1 border-b text-4xl font-semibold">Dom do wynajęcia Serwy</h2>
            <div
              className="container mx-auto grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 pt-6 gap-8">
              <Gallery quantity={12} title="Dom do wynajęcia Serwy" path="serwy/dom"/>
            </div>

            <h2 className="mt-4 pb-1 border-b text-4xl font-semibold">Domek do wynajęcia Serwy</h2>

            <div
              className="container mx-auto grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 pt-6 gap-8">
              <Gallery quantity={8} title="Domek do wynajęcia Serwy" path="serwy/domek"/>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Serwy;

import React from 'react';
import Zoom from 'react-medium-image-zoom'

const Item = ({path, id, title, clickHandler}) => (
  <Zoom>
  <div className="gallery shadow-md">
    <img className="object-cover rounded-md shadow w-full md:w-[350px] h-[250px]" data-index={id} src={require(`../images/${path}/image_${id}.jpg`)} alt={title} onClick={clickHandler} />
  </div>
  </Zoom>
)

export default Item;

import React, { useState } from "react";
import Header from './shared/Header';

const Terms = () => {
  const [show, setShow] = useState(false);
  const [product, setProduct] = useState(false);
  const [deliverables, setDeliverables] = useState(false);
  const [profile, setProfile] = useState(false);

  return (
    <>
      <div>
        <div className="sub-header terms flex flex-col justify-between">
          <Header/>
          <h1
            className="justify-self-end text-white text-5xl md:text-7xl font-bold -mb-[0.5rem] md:-mb-[0.7rem]">Regulamin</h1>
        </div>
        <div className="container mx-auto px-6 mt-10">
          <div className="w-full">
            <h3 className="text-3xl font-semibold pb-1 border-b">Regulamin</h3>

            <ol className="mt-4 list-decimal ml-5 leading-relaxed">
              <li>Pokoje wynajmowane są na doby zgodnie z wcześniejszą rezerwacją.</li>

              <li>Doba trwa od godz. 14:00 do godz. 11:00 następnego dnia.</li>

              <li>Należność za pobyt pobierana jest w dniu przyjazdu, zgodnie z ustalonym przez Państwa terminem rezerwacji
                pokoju. W przypadku wcześniejszego wyjazdu niż w rezerwowanym przez Państwa terminie, należność za całość
                pobytu
                nie ulega zmianie.
              </li>

              <li>W obiekcie oraz terenie przyległym do niego obowiązuje zachowanie ciszy nocnej od godziny 22:00 do godziny
                7:00
              </li>

              <li>Goście zobowiązani są przed wyjazdem zwrócić pokój w stanie nie pogorszonym oraz oddać klucze od pokoju.
              </li>

              <li>Osoby nie zameldowane nie mogą przebywać w obiekcie oraz na jego terenie.</li>

              <li>Zachowanie gości i osób korzystających z usług obiektu nie powinno zakłócać spokojnego pobytu innych gości.
                Zastrzegamy możliwość odmówienia dalszego świadczenia usług osobie, która narusza tę zasadę.
              </li>

              <li>Goście ponoszą odpowiedzialność materialną za wszelkiego rodzaju uszkodzenia lub zniszczenia przedmiotów
                wyposażenia i urządzeń technicznych obiektu, powstałych z ich winy.
              </li>

              <li>Goście są zobowiązani zawiadomić właścicieli o wystąpieniu szkody niezwłocznie po jej stwierdzeniu.</li>

              <li>W przypadku odwołania rezerwacji zadatek nie podlega zwrotowi.</li>

              <li>Ze względu na bezpieczeństwo przeciwpożarowe zabronione jest używanie w pokojach grzałek, żelazek
                elektrycznych i innych podobnych urządzeń nie stanowiących wyposażenia pokoju jak również zabronione jest
                palenie tytoniu i używanie ognia otwartego.
              </li>

              <li>Parking na terenie posesji jest przeznaczony tylko dla gości naszego obiektu. Parking jest bezpłatny oraz
                niestrzeżony. Nie ponosimy odpowiedzialności za pojazdy pozostawione na parkingu oraz rzeczy w nich znajdujące
                się.
              </li>
            </ol>
          </div>
        </div>
      </div>
    </>
  );
}

export default Terms;

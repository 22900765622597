import React from "react";
import Nav from '../shared/Navigation';
import Gallery from '../shared/Gallery';

const Krzysiek = () => {
  return (
    <>
      <div>
        <div className="sub-header krzysiek flex flex-col justify-between">
          <Nav url="/pokoje-u-krzyska" fbUrl="https://www.facebook.com/Pokoje-u-Krzy%C5%9Bka-449121865168366" />
          <h1 className="justify-self-end text-white text-5xl md:text-7xl font-bold -mb-[0.5rem] md:-mb-[0.7rem]">Pokoje
            u Krzyśka</h1>
        </div>
        <div className="container mx-auto px-6 mt-10">
          <div className="w-full">
            <div
              className="container grid sm:grid-cols-1 lg:grid-cols-2 pt-6 gap-8">
              <div>
                <img src={require('../images/pokoje_krzyska/outside/image_2.jpg')} alt="Pokoje u Krzyśka Augustów" />
              </div>
              <div className="lg:pl-8">
                <h2 className="text-3xl font-semibold pb-1 border-b ">Pokoje do wynajęcia w Augustowie</h2>
                <p className="my-4">
                  Oferujemy pokoje 2, 3, 4 osobowe oraz domek i mieszkanie 5 osobowe z niezależnym wejściem.
                  Pokoje wyposażone są w łazienkę z pełnym węzłem sanitarnym, TV, czajnik oraz lodówkę. Do dyspozycji gości
                  jest także ogólna kuchnia, wyposażona w kuchenkę mikrofalową, kuchenkę elektryczną oraz niezbędne naczynia
                  do przygotowywania posiłków. Na terenie naszej posesji jest także parking, ognisko, plac zabaw dla dzieci oraz grill.
                </p>
                <p className="mb-4">
                  Mieszkanie 5 osobowe składa się dwóch sypialni, łazienki oraz kuchni.
                  Jesteśmy pomocni w organizacji wycieczek do Wilna bądź innych miast na Liwie jak i Łotwie.
                  Nie opodal nas znajduję się smażalnia ryb jak i kąpielisko z wypożyczalnią sprzętu pływającego.
                  Wypożyczamy rowery górskie.
                </p>
                <p className="mb-4">
                  Pokoje wyposażone są w łazienkę z pełnym węzłem sanitarnym, TV, czajnik oraz lodówkę. Do dyspozycji
                  gości jest także ogólna kuchnia, wyposażona w kuchenkę mikrofalową, kuchenkę elektryczną oraz
                  niezbędne naczynia do przygotowywania posiłków. Na terenie naszej posesji jest także parking, ognisko,
                  plac zabaw dla dzieci oraz grile.
                </p>
                <p className="mb-4">
                  Zadzwoń do nas, aby uzyskać więcej informacji: <a href="tel:+48693111211"><strong>(+48) 693 111 211</strong></a>
                </p>

              </div>
            </div>

            <h2 className="text-4xl mt-4 pb-1 border-b font-semibold">Otoczenie</h2>
            <div
              className="container mx-auto grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 pt-6 gap-8">
              <Gallery quantity={10} title="Pokoje u Krzyśka Augustów" path="pokoje_krzyska/outside"/>
            </div>

            <h2 className="mt-4 pb-1 border-b text-4xl font-semibold">Domek 4 osobowy w Augustowie</h2>
            <div
              className="container mx-auto grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 pt-6 gap-8">
              <Gallery quantity={4} title="Domek 4 osobowy" path="pokoje_krzyska/domek_4_osobowy"/>
            </div>

            <h2 className="mt-4 pb-1 border-b text-4xl font-semibold">Pokoje 2 osobowe w Augustowie</h2>
            <div
              className="container mx-auto grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 pt-6 gap-8">
              <Gallery quantity={6} title="Pokoje 2 osobowe w Augustowie" path="pokoje_krzyska/pokoje_2_osobowe"/>
            </div>

            <h2 className="mt-4 pb-1 border-b text-4xl font-semibold">Pokoje 2-3 osobowe w Augustowie</h2>
            <div
              className="container mx-auto grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 pt-6 gap-8">
              <Gallery quantity={11} title="Pokoje 2-3 osobowe w Augustowie" path="pokoje_krzyska/pokoje_2-3_osobowe"/>
            </div>

            <h2 className="mt-4 pb-1 border-b text-4xl font-semibold">Pokoje 3-4 osobowe w Augustowie</h2>
            <div
              className="container mx-auto grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 pt-6 gap-8">
              <Gallery quantity={7} title="Pokoje 3-4 osobowe w Augustowie" path="pokoje_krzyska/pokoje_3-4_osobowe"/>
            </div>

            <h2 className="mt-4 pb-1 border-b text-4xl font-semibold">Mieszkanie 5 osobowe w Augustowie</h2>
            <div
              className="container mx-auto grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 pt-6 gap-8">
              <Gallery quantity={7} title="Mieszkanie 5 osobowe w Augustowie" path="pokoje_krzyska/mieszkanie_5_osobowe"/>
            </div>

          </div>
        </div>
      </div>
    </>
  );
}

export default Krzysiek;

import React from 'react';
import YouTube from 'react-youtube';

const Video = ({ id }) => {
  const opts = {
    height: '390',
    width: '640',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
      modestbranding: 0,

    },
  };

  const _onReady = event => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  }

  return <YouTube className="embed-container" videoId={id} opts={opts} onReady={_onReady}/>
};

export default Video;


import React from "react";
import { Link } from "react-router-dom";

export default function Home() {

  return (
    <>
      <div className="bg-wrapper">
        <div className="bg-container ania flex justify-center items-center border-b-4 sm:border-b-0 ">
          <div className="layer"></div>
          <h2 className="text-4xl md:text-6xl text-white font-black">
            <Link to="/pokoje-u-ani">Pokoje <br className="lg:hidden"/>u Ani</Link>
          </h2>

        </div>
        <div className="bg-container serwy flex justify-center items-center md:items-end border-b-4 sm:border-b-0">
          <div className="layer"></div>

          <h2 className="text-4xl md:text-6xl text-white font-black pb-4">
            <Link to="/domek-serwy">
              Dom i Domek <br className="lg:hidden"/>Serwy
            </Link>
          </h2>

        </div>
        <div className="bg-container krzysiek flex justify-center items-center">
          <div className="layer"></div>
          <h2 className="text-4xl md:text-6xl text-white font-black">
            <Link to="/pokoje-u-krzyska">
              Pokoje <br className="lg:hidden"/>u Krzyśka
            </Link>
          </h2>
        </div>
      </div>
    </>
  );
}

import React, { useState } from 'react';
import { clsx } from 'clsx';

const Hamburger = ({toggleMenu, currentState}) => {
  const [isOpen, setIsOpen] = useState(currentState);

  const handleClick = (e) => {
    toggleMenu(isOpen);
    setIsOpen(!isOpen);
    // currentState = !currentState;
  }

  console.log('=======', currentState);
  return (
    <>
          <button className="text-gray-500 w-10 h-10 relative focus:outline-none bg-white md:hidden"
                  onClick={handleClick}>
            <span className="sr-only">Open main menu</span>
            <div className="block w-5 absolute left-1/2 top-1/2  transform  -translate-x-1/2 -translate-y-1/2">
            <span aria-hidden="true"
                  className={clsx({ 'rotate-45': isOpen }, { '-translate-y-1.5': !isOpen }, 'block absolute h-0.5 w-5 bg-current transform transition duration-500 ease-in-out')}/>

              <span aria-hidden="true"
                    className={clsx({ 'opacity-0': isOpen }, 'block absolute  h-0.5 w-5 bg-current  transform transition duration-500 ease-in-out')}/>

              <span aria-hidden="true"
                    className={clsx({ '-rotate-45': isOpen }, { 'translate-y-1.5': !isOpen }, 'block absolute  h-0.5 w-5 bg-current transform  transition duration-500 ease-in-out')}/>
            </div>
          </button>
    </>
  )
}

export default Hamburger;

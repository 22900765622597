import React, { useState } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import Hamburger from './Hamburger';
import fbIcon from '../fb-icon.svg';

const Navigation = ({url, fbUrl}) => {
  const [menuOpen, setMenuOpen] = useState(true);
  const [show, setShow] = useState(false);
  const [product, setProduct] = useState(false);
  const [deliverables, setDeliverables] = useState(false);
  const [profile, setProfile] = useState(false);

  const toggleMenu = (state) => {
    setMenuOpen(state);
  }

  return (
    <>
      <header className="w-full relative">
        <div className="container mx-auto flex flex-row absolute top-0 right-0 md:relative z-10 justify-end">
          <ul
            className={clsx({ 'hidden': menuOpen }, "md:flex flex-col md:flex-row bg-black text-white bg-opacity-50 rounded-b-md")}>
            <li
              className="w-32 h-12 flex items-center pl-4 justify-start md:justify-center text-sm text-gray-800">
              <Link className="text-white" to="/">Strona główna</Link>
            </li>
            <li className="w-32 h-12 flex items-center pl-4 justify-start md:justify-center text-sm text-gray-800">
              <Link className="text-white"
                    to={`${url}`} onClick={() => toggleMenu(true)}>Pokoje</Link></li>
            <li className="w-32 h-12 flex items-center pl-4 justify-start md:justify-center text-sm text-gray-800 hidden">
              <Link className="text-white"
                    to={`${url}/spacer`}>Wirtualny spacer</Link></li>
            <li className="w-32 h-12 flex items-center pl-4 justify-start md:justify-center text-sm text-gray-800 hidden">
              <Link className="text-white"
                    to={`${url}/atrakcje`}>Atrakcje</Link></li>
            <li className="w-32 h-12 flex items-center pl-4 justify-start md:justify-center text-sm text-gray-800">
              <Link className="text-white"
                    to={`/kontakt`}>Kontakt</Link></li>
            {fbUrl && (<li className=" flex items-center pl-4 justify-start md:justify-center text-sm text-gray-800">
              <a className="text-white" href={fbUrl} target="_blank" rel="nofollow"><img src={fbIcon} alt="Odwiedź nas na Facebooku" className="w-6" /></a></li>)}
            <li className="w-32 h-12 flex items-center pl-4 justify-start md:justify-center text-sm text-gray-800">
              <Link className="text-white"
                    to={`/terms`}>Regulamin</Link></li>
          </ul>
          <Hamburger toggleMenu={toggleMenu} currentState={!menuOpen}/>
        </div>
      </header>
    </>
  )
}

export default Navigation;

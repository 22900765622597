import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { createBrowserHistory } from 'history'
import reportWebVitals from './reportWebVitals';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Home from './Home';
import Ania from './ania/Ania';
import Krzysiek from './krzysiek/Krzysiek';
import Serwy from './serwy/Serwy';
import Contact from './Contact';
import Terms from './Terms';
import NoMatch from './NoMatch';
import 'react-medium-image-zoom/dist/styles.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
const history = createBrowserHistory();

root.render(
  <React.StrictMode>
    <Router history={history}>
      <Routes>
        <Route path="/pokoje-u-ani/" element={<Ania />}/>
        <Route path="/pokoje-u-ani/:page" element={<Ania />}/>
        <Route path="/pokoje-u-krzyska" element={<Krzysiek />}/>
        <Route path="/domek-serwy" element={<Serwy />}/>
        <Route path="/kontakt" element={<Contact />}/>
        <Route path="/terms" element={<Terms />}/>
        <Route path="/" element={<Home/>}/>
        <Route path="*" element={<NoMatch />} />
      </Routes>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//
reportWebVitals();
